import React from "react";
import Header from "../components/Header/header";
import SecondHeader from "../components/Header/secondheader";
import Footer from "../components/Footer/index";
import styled from "styled-components";
import {
  IndiaAIFestivalComponents,
  IndiaAIFestivalTarget,
  IndiaAIFestivalTimeline,
  IndiaAIFestivalSpecialAwards,
  IndiaAIFestivalHowToParticipate,
  IndiaAIFestivalBanner,
  IndiaAIFestivalContact,
  IndiaAIFestivalMainAwards,
  IndiaAIFestivalSubmissionGuidelines,
  IndiaAIFestivalGuidelines,
  IndiaAIFestivalProjectShowcase,
  IndiaAIFestivalDeadlineNotice,
} from "../components/IndiaAIFestival";
import { BlinkingNode, LanguageSwitcher } from "../components/shared";

const Section = styled.div`
  margin-top: 35px;

  @media (min-width: 992px) {
    margin-top: 75px;
  }
`;

const Section2 = styled.div`
  margin-top: 15px;

  @media (min-width: 992px) {
    margin-top: 30px;
  }
`;

const IndiaAIFestivalPage = () => {
  return (
    <>
      {localStorage.getItem("jwtToken") == null ? (
        <Header hideSubHeader />
      ) : (
        <SecondHeader hideSubHeader />
      )}

      <div className="festival-section gray-light-bg">
        {
          <div class="d-flex justify-content-center w-100">
            <BlinkingNode>
              <IndiaAIFestivalDeadlineNotice />
            </BlinkingNode>
          </div>
        }
        <div className="container d-flex justify-content-end">
          <LanguageSwitcher />
        </div>
        <div className="blog-container shadow px-0 pt-0 mt-0">
          <div className="mar-t-15">
            <IndiaAIFestivalBanner />
          </div>

          <div className="container">
            <Section2>
              <IndiaAIFestivalProjectShowcase />
            </Section2>
          </div>

          <Section>
            <IndiaAIFestivalTarget />
          </Section>

          <div className="container">
            <div className="px-md-2 px-xl-5 mx-auto">
              <Section className="row justify-content-center">
                <div className="col-md-5">
                  <IndiaAIFestivalMainAwards />
                </div>

                <div className="offset-md-1 col-md-5 mt-4 mt-md-0">
                  <IndiaAIFestivalSpecialAwards />
                </div>
              </Section>
            </div>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalHowToParticipate />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalSubmissionGuidelines />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalComponents />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalTimeline />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalGuidelines />
            </Section>

            <Section className="px-md-2 px-xl-5 mx-auto">
              <IndiaAIFestivalContact />
            </Section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndiaAIFestivalPage;
