import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authAction";
import Footer from "../components/Footer/index";
import SecondHeader from "../components/Header/secondheader";
import Bio from "./Bio";
import EventCounter from "./EventCounter";
import WhatsNew from "./WhatsNew";
import StartLearning from "./StartLearning";
import LatestinAI from "./LatestInAI";
import LatestBlogs from "./LatestBlogs";
import LatestEvent from "./LatestEvent";
import Socials from "./Socials";
import Popup from "reactjs-popup";
import styled from "styled-components";
import { BlinkingNode } from "../components/shared";
import { isSubmissionOpen } from "../utils/impactFestival";

const ImageSection = styled.div`
  aspect-ratio: 14 / 10;
  overflow: hidde̦n;
  width: 100%;

  @media (min-width: 768px) {
    aspect-ratio: 29 / 10;
  }
`;

const BannerImage = styled.img`
  object-fit: cover;
  object-position: left;
  height: 100%;
`;

const StyledText = styled.p`
  font-size: 1.4rem;
  color: #0067b4;
  font-weight: 600;
  line-height: 1.25;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      blog: [],
      events: [],
      myDivToFocus: React.createRef(),
      isOpen: true,
    };
  }

  getEvents() {
    const studentId = localStorage.getItem("studentId");
    fetch(
      `${process.env.REACT_APP_API_URL}/scheduled-events?student=${studentId}`
    )
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          events: result.events,
        });
      });
  }

  getBlog() {
    fetch(`${process.env.REACT_APP_API_URL}/blogs`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ blog: data.blogs });
      });
  }

  getData() {
    const id = localStorage.getItem("studentId");
    const token = localStorage.getItem("jwtToken");
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/` + id, {
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  startProcess() {
    const id = localStorage.getItem("studentId");
    const token = localStorage.getItem("jwtToken");
    fetch(`${process.env.REACT_APP_API_URL}/startprogress/${id}`, {
      headers: {
        "x-access-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  scrollMore() {}

  componentDidMount() {
    this.getData();
    this.getEvents();
    this.getBlog();
  }

  render() {
    const { data, blog, events } = this.state;
    const _isSubmissionOpen = isSubmissionOpen();

    return (
      <React.Fragment>
        <SecondHeader />
        {localStorage.getItem("showDashboardPopUp") === null && (
          <Popup
            trigger={<button style={{ display: "none" }}></button>}
            position="top center"
            open={this.state.isOpen}
            closeOnDocumentClick={false}
            overlayStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.80)",
              overflow: "hidden",
            }}
          >
            <div className="d-flex flex-column align-items-between h-100">
              <ImageSection className="d-flex flex-column justify-content-center">
                <BannerImage src="images/india-ai-impact-festival-2024-banner.jpg" />
              </ImageSection>

              <div className="py-4 px-2 px-md-5 text-center w-75 mx-auto d-flex align-items-center justify-content-center">
                <StyledText className="mb-0">
                  {_isSubmissionOpen
                    ? "Do you want to submit your entry for the India AI Impact Festival 2024?"
                    : "Submissions closed for India AI Impact Festival 2024"}
                </StyledText>
              </div>
              <div className="mt-auto d-flex justify-content-center p-2">
                {_isSubmissionOpen && (
                  <div>
                    <a
                      href="/india-ai-impact-festival-registration-2024"
                      className="btn btn-primary rounded-10 py-2 px-4"
                    >
                      Yes
                    </a>
                  </div>
                )}
                <div>
                  <button
                    onClick={() => {
                      this.setState({ isOpen: false });
                      localStorage.setItem("showDashboardPopUp", true);
                    }}
                    className="btn btn-secondary rounded-10 py-2 px-4 ml-3"
                  >
                    {_isSubmissionOpen ? "No" : "Close"}
                  </button>
                </div>
              </div>
            </div>
          </Popup>
        )}
        <section className="our-blog-section gray-light-bg single-blog-container">
          {_isSubmissionOpen && (
            <div className="d-flex justify-content-center mt-5 mt-md-0">
              <BlinkingNode>
                <a
                  href="/india-ai-impact-festival-registration-2024"
                  className="btn btn-primary"
                >
                  Submit your entry for India AI Impact Festival 2024
                </a>
              </BlinkingNode>
            </div>
          )}
          <div className="blog-container container mx-auto shadow rounded">
            <Bio data={data} />
            <EventCounter />
            <WhatsNew />
            <StartLearning />
            <LatestEvent data={events} />
            <LatestinAI data={data} />
            <LatestBlogs data={blog} />
            <Socials />
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
