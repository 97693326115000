import React, { Component } from "react";
import ChangingFields from "./ChangingFields/ChangingFields";

class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      email: "",
      disableBtn: false,
      subscribeSuccess: false,
      subscribeFailed: false,
      showForm: true,
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({
      disableBtn: true,
      subscribeSuccess: false,
      subscribeFailed: false,
      showForm: true,
    });
    fetch(
      `${process.env.REACT_APP_API_URL}/subscribemail?email=${this.state.email}`
    ).then((res) => {
      res.json();
      if (res.status === 200) {
        this.setState({ subscribeSuccess: true, showForm: false });
      } else {
        this.setState({ subscribeFailed: true });
      }
      this.setState({ disableBtn: false });
    });
  }

  componentDidMount() {
    //
  }

  render() {
    const initialState = {
      title: "About",
      description: (
        <div>
          Your curious? Learn more about us and how the AI Student Community
          functions!
          <a href="/about" className=" btn btn-link d-block  no-underline">
            Click here to know more
          </a>
        </div>
      ),
      icon: "fa-file-o",
      imageUrl: "images/contact-us.png",
      redirectionLink: "/about",
    };
    const reasons = [
      initialState,
      {
        title: "Community guidelines",
        description: (
          <div>
            We take our Community Guidelines seriously, you must comply to these
            guidelines to be a part of the Community to ensure an amazing
            experience for everyone!
            <a
              href="/community-guidelines"
              className=" btn btn-link d-block no-underline"
            >
              Click here to know more
            </a>
          </div>
        ),
        icon: "fa-navicon",
        imageUrl: "images/contact-us.png",
        redirectionLink: "/community-guidelines",
      },
      {
        title: "FAQ",
        description: (
          <div>
            This is where you will find answers to all the Whats and Whys you
            have right now.
            <a href="/faq" className=" btn btn-link d-block no-underline">
              Click here to know more
            </a>
          </div>
        ),
        icon: "fa-comment-o",
        imageUrl: "images/contact-us.png",
        redirectionLink: "/faq",
      },
    ];

    return (
      <section className="vertical-scrolling animated-row">
        <div className="section-inner">
          {/* <div className="row justify-content-center">
            <div className="col-md-9 wide-col-laptop">
              <div className="contact-section">
                <div className="row">
                  <div className="col-md-6 animate" data-animate="fadeInUp">
                    <div className="contact-box contact-details">
                      <a href="/about" className="contact-row p-4 mb-md-5">
                        About
                      </a>
                      <a
                        href="/participation-guidelines"
                        className="contact-row p-4 mb-md-5"
                      >
                        Participation Guidelines
                      </a>
                      <a href="/faq" className="p-4 contact-row mb-5">
                        FAQ
                      </a>
                    </div>
                  </div>
                  <div
                    className="col-md-6 animate contact-details"
                    data-animate="fadeInUp"
                  >
                    <h3 className="text-left contact-heading">
                      Subscribe to our Newsletter
                    </h3>
                    <p className="text-white text-left contact-text">
                      Want to get updates, important community news, and other
                      learning materials? Subscribe by adding your Email ID and
                      get the hand-picked information to efficiently utilize the
                      community. You may unsubscribe from these at any time.
                    </p>
                    {this.state.subscribeSuccess && (
                      <div class="alert alert-success" role="alert">
                        Thank you for subscribing!
                      </div>
                    )}
                    {this.state.subscribeFailed && (
                      <div class="alert alert-danger" role="alert">
                        Oops! Something went wrong. Please try again or come
                        back later.
                      </div>
                    )}
                    {this.state.showForm && (
                      <form
                        id="ajax-contact"
                        method="post"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="input-field">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control text-white"
                            placeholder="Enter your email address"
                            value={this.state.email}
                            onChange={(e) =>
                              this.handleFormValueChange("email", e)
                            }
                            required
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={this.state.disableBtn}
                          className={
                            (this.state.disableBtn ? "btn disabled" : "btn") +
                            " p-2 mb-5"
                          }
                        >
                          Subscribe
                        </button>
                      </form>
                    )}
                    <div id="form-messages" className=""></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <ChangingFields
            title={"contact us"}
            setDetails={this.setDetails}
            reasons={reasons}
            inverted={true}
            initialState={initialState}
          />

          <a
            href="mailto:aistudentcommunity.cbse@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-mail"
          >
            aistudentcommunity.cbse@gmail.com
          </a>
        </div>
      </section>
    );
  }
}

export default ContactSection;
