import React, { Component } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "./Card";
import Slider from "./Slider";

class LatestinAI extends Component {
  render() {
    const data = this.props.data;
    const latestData = data.length > 0 ? data[0].newsData : [];
    const footer = (link) => (
      <div>
        <a
          href={link}
          target="_blank"
          className="btn btn-primary"
          style={{ width: "100%", borderRadius: "0px 0px 5px 5px" }}
        >
          Read full article
        </a>
      </div>
    );

    return (
      <div className="latest-ai p-4 m-3 mt-4 pt-2">
        <h2 className="pl-2 my-1">LATEST IN AI</h2>
        <Slider>
          {latestData.map((data) => (
            <Card data={data} footer={footer} key={data.id} />
          ))}
        </Slider>
      </div>
    );
  }
}

export default LatestinAI;
