import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  list-style-type: unset;
`;

const Box = styled.div`
  border: 3px solid #ff8f4e;
  border-radius: 10px;
`;

const ReadMoreLink = styled.a`
  color: blue;
  cursor: pointer;
`;

const IndiaAIFestivalSubmissionGuidelines = () => {
  const { t } = useTranslation();
  const [showDetails, setShowDetails] = useState({
    creators: false,
    shapers: false,
    nurturers: false,
  });

  return (
    <>
      <h2 className="mb-0 text-center">{t("what_to_submit")}</h2>
      <div className="mt-md-2 row">
        <div className="col-md-4 mt-3">
          <Box className="h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-top justify-content-center">
            <div>
              <h5 className="text-blue text-center">{t("impact_creators")}</h5>
              <StyledList>
                <li className="lead">{t("step_1_creators")}</li>
                <li className="lead">{t("step_2_creators")}</li>

                <ReadMoreLink
                  onClick={() =>
                    setShowDetails({
                      ...showDetails,
                      creators: !showDetails.creators,
                    })
                  }
                >
                  {showDetails.creators ? (
                    <small className="text-primary">{t("hide_details")}</small>
                  ) : (
                    <small className="text-primary">
                      {t("click_here_to_view_details")}
                    </small>
                  )}
                </ReadMoreLink>

                {showDetails.creators && (
                  <StyledList>
                    <div className="mb-0">{t("poster_and_video_details")}</div>
                    <li className="lead">
                      {t("clarity_of_problem_statement")}
                    </li>
                    <li className="lead">{t("innovation_and_creativity")}</li>
                    <li className="lead">{t("technical_proficiency")}</li>
                    <li className="lead">
                      {t("scalability_and_sustainability")}
                    </li>
                    <li className="lead">
                      {t("inclusivity_and_accessibility")}
                    </li>
                    <li className="lead">{t("ethical_considerations")}</li>
                    <li className="lead">{t("presentation_quality")}</li>
                  </StyledList>
                )}
              </StyledList>
            </div>
          </Box>
        </div>
        <div className="col-md-4 mt-3">
          <Box className="h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-top justify-content-center">
            <div>
              <h5 className="text-blue text-center">{t("impact_shapers")}</h5>
              <StyledList>
                <li className="lead">{t("step_1_shapers")}</li>
                <li className="lead">{t("step_2_shapers")}</li>
                <ReadMoreLink
                  onClick={() =>
                    setShowDetails({
                      ...showDetails,
                      nurturers: !showDetails.nurturers,
                    })
                  }
                >
                  {showDetails.nurturers ? (
                    <small className="text-primary">{t("hide_details")}</small>
                  ) : (
                    <small className="text-primary">
                      {t("click_here_to_view_details")}
                    </small>
                  )}
                </ReadMoreLink>

                {showDetails.nurturers && (
                  <StyledList>
                    <div className="mb-0">{t("poster_and_video_details")}</div>
                    <li className="lead">{t("innovation_and_creativity")}</li>
                    <li className="lead">{t("aligned_to_un_sdg")}</li>
                    <li className="lead">
                      {t("extending_outreach_of_best_practices")}
                    </li>
                    <li className="lead">{t("ethical_considerations")}</li>
                    <li className="lead">{t("presentation_quality")}</li>
                    <li className="lead">
                      {t(
                        "coverage_of_ai_domains_in_teaching_learning_practice"
                      )}
                    </li>
                  </StyledList>
                )}
              </StyledList>
            </div>
          </Box>
        </div>
        <div className="col-md-4 mt-3">
          <Box className="h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-top justify-content-center">
            <div>
              <h5 className="text-blue text-center">{t("impact_nurturers")}</h5>
              <StyledList>
                <li className="lead">{t("step_1_nurturers")}</li>
                <li className="lead">{t("step_2_nurturers")}</li>
                <ReadMoreLink
                  onClick={() =>
                    setShowDetails({
                      ...showDetails,
                      shapers: !showDetails.shapers,
                    })
                  }
                >
                  {showDetails.shapers ? (
                    <small className="text-primary">{t("hide_details")}</small>
                  ) : (
                    <small className="text-primary">
                      {t("click_here_to_view_details")}
                    </small>
                  )}
                </ReadMoreLink>
                {showDetails.shapers && (
                  <StyledList>
                    <div className="mb-0">{t("poster_and_video_details")}</div>
                    <li className="lead">{t("innovation_and_creativity")}</li>
                    <li className="lead">{t("aligned_to_un_sdg")}</li>
                    <li className="lead">
                      {t("extending_outreach_of_best_practices")}
                    </li>
                    <li className="lead">{t("ethical_considerations")}</li>
                    <li className="lead">{t("presentation_quality")}</li>
                    <li className="lead">
                      {t("duration_of_implementing_best_practices")}
                    </li>
                  </StyledList>
                )}
              </StyledList>
            </div>
          </Box>
        </div>
      </div>
    </>
  );
};

export default IndiaAIFestivalSubmissionGuidelines;
