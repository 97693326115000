import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json";
import hiTranslation from "./locales/hi.json";
import taTranslation from "./locales/ta.json";
import teTranslation from "./locales/te.json";
import knTranslation from "./locales/kn.json";
import mlTranslation from "./locales/ml.json";
import orTranslation from "./locales/or.json";
import bnTranslation from "./locales/bn.json";
import guTranslation from "./locales/gu.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    hi: { translation: hiTranslation },
    ta: { translation: taTranslation },
    te: { translation: teTranslation },
    kn: { translation: knTranslation },
    ml: { translation: mlTranslation },
    or: { translation: orTranslation },
    bn: { translation: bnTranslation },
    gu: { translation: guTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
