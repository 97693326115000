import React, { Component } from "react";
import Twitter from "../components/Twitter/twitter";

class Socials extends Component {
  constructor(props) {
    super(props);
    let instagramAccountName = "ai4.youth";
    this.state = {
      instagramAccountName,
      instagramAccountUrl: `https://www.secure.instagram.com/${instagramAccountName}/`,
      photos: [],
    };
  }

  fetchInstagramPhotos() {
    fetch(`${process.env.REACT_APP_API_URL}/instagram-photos/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        try {
          if (res.graphql.user.edge_owner_to_timeline_media.edges) {
            let edges = res.graphql.user.edge_owner_to_timeline_media.edges;
            for (let index in edges) {
              let edge = edges[index];
              this.setState({
                photos: [
                  ...this.state.photos,
                  {
                    href: "https://www.instagram.com/p/" + edge.node.shortcode,
                    thumbnail: edge.node.display_url,
                  },
                ],
              });
            }
          }
        } catch (ex) {
          console.log(ex);
        }
      });
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    window.addEventListener("load", this.handleWindowLoad);
    this.fetchInstagramPhotos();
  }

  handleWindowLoad() {
    var twitterFrame = document.getElementById("twitterFrame");
    var iframe = twitterFrame.getElementsByTagName("iframe")[0];
    var style = document.createElement("style");
    style.textContent = `
        body .timeline-Body{
          border:0px;
        }
        body .timeline-Header {
          padding: 2px 10px;
        }
        .timeline-InformationCircle {
          top: 12px !important;
        }
        body .timeline-Header-title {
          font-size: 18px !important;
        }
        body .timeline-Tweet-text {
          margin-bottom: 0;
          font-size: 14px !important;
          line-height: 1.25 !important;
        }
        body .timeline-Footer {
          padding: 8px 15px;
        }
      `;
    if (iframe && iframe.contentDocument) {
      iframe.contentDocument.head.appendChild(style);
    }
  }
  render() {
    return (
      <div className="socials social-media-section p-4 m-3 mt-4 pb-5">
        <h2 className="mb-5">SOCIALS</h2>
        <div className="row ">
          <div className="col-md-6 d-flex align-items-center">
            <div className="d-flex" style={{ flexGrow: "1" }}>
              <div className="col-1 col-md-2 social-vertical-text pl-0">
                INSTAGRAM|AI4.YOUTH
              </div>
              <div className="col-11 col-md-10 instagram-highlights-block pl-0">
                <div className="instagram-highlights ml-md-auto">
                  {this.state.photos.slice(0, 5).map((element, index) => (
                    <a
                      key={index}
                      href={element.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ backgroundImage: `url('${element.thumbnail}')` }}
                      className="instagram-card shadow"
                    ></a>
                  ))}
                  <a
                    href={this.state.instagramAccountUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="instagram-card shadow view-on-instagram"
                  >
                    <img src="icons/instagram.svg" alt="Instagram" />
                    <p className="text-center text-white">View on Instagram</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 twitter-highlights d-flex align-items-center">
            <div className="d-flex" style={{ flexGrow: "1" }}>
              <div className="col-1 col-md-2 social-vertical-text pl-0">
                TWITTER|AI4YOUTH
              </div>
              <div className="col-11 col-md-10">
                <div className=" shadow mr-5 rounded-10" id="twitterFrame">
                  <Twitter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Socials;
