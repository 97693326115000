import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import Footer from "../Footer/index";
import SecondHeader from "../Header/secondheader";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdCheckCircle } from "react-icons/md";
class Letslearn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const id = localStorage.getItem("studentId");
    fetch(`${process.env.REACT_APP_API_URL}/letslearn/` + id, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <SecondHeader />
        <section className="our-blog-section gray-light-bg single-blog-container">
          {data.map((data, key) => {
            if (data.first_time_letslearn) {
              return (
                <div
                  className="blog-container container mx-auto shadow rounded"
                  key={key}
                >
                  <div className="row mx-0">
                    <div className="col-12 px-0">
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <h4 className="alert-heading">You're curious!</h4>
                        <p className="mb-0">
                          This is an amazing place for you.
                        </p>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <div className="blog-container container mx-auto shadow rounded">
            {data.map((data, i) => (
              <div className="row " key={i}>
                {data.studentData.map((studentData, j) => (
                  <div key={j}>
                    <div className="row col-sm-12 no-r-padding">
                      <div className="col-md-9 ">
                        <h1 className="text-left">Let's Learn</h1>
                        <p className="sub-heading ">
                          Learn the essentials of Artificial Intelligence and
                          understand the fastest-growing and most
                          transformational technologies of our time through
                          video resources. Develop your skill-set and learn the
                          ins-and-outs of Python to get the necessary skills for
                          building AI. Start your journey today!
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {data.map((data, i) => (
              <div>
                {data.courseData.map((courseData, j) => (
                  <div className=" mar-b-30 course-card-lets-learn" key={j}>
                    <div className="row mar-t-15 ">
                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-md-8">
                            <h3 className="text-left">{courseData.title} </h3>
                          </div>
                          <div className="col-md-4 text-right no-r-padding">
                            <a
                              className="blue-btn"
                              href={"/single_course/" + courseData.startsWith}
                            >
                              Start Learning
                            </a>
                          </div>
                        </div>
                        <div>
                          <p className="lesson-desc without-limit text-left mar-t-15">
                            {courseData.desc}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 mar-t-15">
                        <h4 className="text-left">Course Module</h4>
                      </div>
                      <div className="col-sm-7">
                        {courseData.modules.map((modules, k) => (
                          <ul key={k}>
                            {modules.topics.map((topic, index) => (
                              <ul key={index}>
                                <li
                                  className="topic-title"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/single_course/${topic.order}`
                                    )
                                  }
                                >
                                  {topic.watched === true ? (
                                    <MdCheckCircle
                                      className="topic-icon"
                                      size={30}
                                      color="green"
                                    />
                                  ) : (
                                    <AiOutlineCheckCircle
                                      size={30}
                                      color="green"
                                      className="topic-icon"
                                    />
                                  )}

                                  {topic.title}
                                </li>
                              </ul>
                            ))}
                          </ul>
                        ))}
                      </div>
                      <div className="col-sm-5 text-center">
                        <img
                          className="course-lets-img"
                          src={courseData.cover}
                          alt="letslearnimg"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className=" mar-b-30 course-card-lets-learn">
              <div className="row no-r-padding">
                <div className="col-md-9">
                  <h1 className="text-left">Learning Resources</h1>
                  <p className="sub-heading ">
                    Take a deep dive into the essentials of Artificial
                    Intelligence and level up your AI skills with the learning
                    resources curated for you!
                  </p>
                </div>
                <div className="col-md-3 mt-3 text-right no-r-padding">
                  <a className="blue-btn" href="/learningResources">
                    {" "}
                    Learning Resources{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

Letslearn.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Letslearn);
