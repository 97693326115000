import React, { Component } from "react";
import EventCard from "../components/Event/EventCard";
import Slider from "./Slider";
import { Link } from "react-router-dom";
import moment from "moment";

class LatestEvent extends Component {
  render() {
    const events = this.props.data;
    const currentTime = new Date();
    const upcomingEvents = [];
    const pastEvents = [];

    for (var i = 0; i < events.length; i++) {
      if (moment(events[i].end_time).unix() > moment(currentTime).unix()) {
        upcomingEvents.push(events[i]);
      } else {
        pastEvents.push(events[i]);
      }
    }

    upcomingEvents.sort(function (x, y) {
      return moment(x.start_time).unix() - moment(y.start_time).unix();
    });

    pastEvents.sort(function (x, y) {
      return moment(y.start_time).unix() - moment(x.start_time).unix();
    });

    const footer = (creator, id, isRegistered, end_time) => (
      <div className="d-flex" style={{ width: "100%" }}>
        <div style={{ fontSize: "12px", width: "100%" }} className="d-flex p-2">
          <div className="circle mr-2"></div>
          <span>{creator}</span>
        </div>
        <div className="d-flex justify-content-end" style={{ width: "100%" }}>
          <Link
            to={`single_event/${id}`}
            className="btn btn-outline-primary know-more"
          >
            Know More
          </Link>
          {moment(end_time).unix() > moment(currentTime).unix() && (
            <button
              className="btn btn-primary enroll-button"
              onClick={() => {
                isRegistered === true
                  ? this.unEnrollEvent(id)
                  : this.enrollEvent(id);
              }}
            >
              {isRegistered === true ? "Enrolled" : "Enroll"}
            </button>
          )}
        </div>
      </div>
    );
    return (
      <div className="latest-events p-4 m-3 mt-4">
        <div className="d-flex flex-column align-items-start flex-md-row align-items-md-center px-2">
          <h2>LATEST EVENTS</h2>
          <Link to="/event" className="btn btn-primary link-button ml-auto ">
            View all events
          </Link>
        </div>
        <Slider buttonMoveAway={true}>
          {upcomingEvents.map((event) => (
            <EventCard data={event} key={event.id} />
          ))}
          {pastEvents.map((event) => (
            <EventCard data={event} key={event.id} />
          ))}
        </Slider>
      </div>
    );
  }
}

export default LatestEvent;
