import * as actionType from "../actions/type";
const INITIAL_STATE = {
  error: null,
};

export const authFail = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_AUTH_FAIL:
      return {
        ...state,
        error: action.payload,
        message: action.payload.response.data.message,
      };

    default:
      return state;
  }
};

export default authFail;
