import React from "react";
import { connect } from "react-redux";
import IntelLogo from "../../static/img/Intel.png";
import KMVLogo from "../../static/img/kmv.png";
import AISCLogo from "../../static/img/aisc-logo.png";
import styled from 'styled-components'

const Logo = styled.img`
  width: auto;
  max-width: 100%;
  height: auto !important;
  max-height: 60px;
`

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="position-fixed w-100 header-wrapper"
          style={{ top: "0px" }}
        >
          <div className="header-container">
            <div className="row header-logos-container content-container py-md-2">
              <div className="col-4 header-col d-flex align-items-center justify-content-start">
                <Logo
                  className="header-logo"
                  src={KMVLogo}
                  alt="KMVLogo"
                />
              </div>
              <div className="col-4 header-col d-flex align-items-center justify-content-center">
                <Logo
                  className="header-logo aisc-logo"
                  src={AISCLogo}
                  alt="aiscLogo"
                />
              </div>
              <div className="col-4 header-col d-flex align-items-center justify-content-end">
                <Logo
                  className="header-logo intel-logo p-md-2"
                  src={IntelLogo}
                  alt="IntelLogo"
                />
              </div>
            </div>
          </div>

          {!this.props.hideSubHeader && (
            <header className="header">
              <nav
                className={
                  "navbar navbar-expand-lg p-0 " +
                  (this.props.bgColor && this.props.bgColor === "white"
                    ? "custom-nav white-bg"
                    : "")
                }
              >
                <div className="header-links-container content-container">
                  <a href="/" className="header-brand text-uppercase">
                    AI Student Community
                  </a>
                  <div className="header-links">
                    <a href="/about" className="header-link">
                      About
                    </a>
                    <a href="/learningResources" className="header-link">
                      Learning Resources
                    </a>
                    <a href="/exemplar-blogs" className="header-link">
                      Exemplar Blogs
                    </a>
                    <li className="nav-item dropdown header-link">
                      <a
                        href="#"
                        className="border-0 bg-transparent px-0"
                        id="indiaAIDropdown"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Impact Festival
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="indiaAIDropdown"
                      >
                        <a className="dropdown-item" href="/india-ai-impact-festival-2024">
                          2024
                        </a>
                      </div>
                    </li>
                    <a href="/login" className="header-link">
                      Login
                    </a>
                    <a href="/signup" className="header-link sign-up">
                      Sign up
                    </a>
                  </div>
                </div>
              </nav>
            </header>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({}))(Header);
