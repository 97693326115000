import moment from 'moment-timezone';

/**
 * The time zone for Indian Standard Time (IST).
 *
 * @constant {string}
 */
export const IST_TIMEZONE = 'Asia/Kolkata';

/**
 * A mapping of end dates for each year in Indian Standard Time (IST).
 * Only the date part is defined, the time part will be automatically set to the end of the day.
 *
 * @constant {Object.<number, string>}
 */
export const SUBMISSION_END_TIMES = {
  2024: '2024-05-28',
  // Add more years as needed
};

/**
 * Checks if the current time is past the submission end time for the current year in IST.
 * 
 * @returns {boolean} True if the current time is past the submission end time for the current year in IST, false otherwise.
 */
export const isSubmissionOpen = () => {
  const currentYear = moment().tz(IST_TIMEZONE).year();
  const targetDateStr = SUBMISSION_END_TIMES[currentYear];

  // If for an year we don't have a submission end date, that means the festival
  // has not yet been planned. In that case we will return false so it hides the sections
  // related to Impact Festival which is the main purpose of this function.
  if (!targetDateStr) {
    return false;
  }

  const targetTime = moment.tz(targetDateStr, IST_TIMEZONE).endOf('day');
  const currentTime = moment.tz(IST_TIMEZONE);

  return currentTime.isBefore(targetTime);
};
