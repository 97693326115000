import React, { Component } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

class twitter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="rounded-10">
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="Ai4Youth"
          options={{ height: 350, width: "100%" }}
          theme="light"
          noHeader
          noFooter
        />
      </div>
    );
  }
}

export default twitter;
