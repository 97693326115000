import React from "react";
import IndiaAIFestivalCTA from "./IndiaAIFestivalCTA";

const IndiaAIFestivalBanner = (props) => {
  return (
    <div className="d-flex align-items-center flex-column">
      <img src="images/india-ai-impact-festival-2024-banner.jpg" className="w-100" />
      <IndiaAIFestivalCTA />
    </div>
  );
};

export default IndiaAIFestivalBanner;
