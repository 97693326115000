import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  list-style-type: disc;
`;

const Box = styled.div`
  border: 3px solid #ff8f4e;
  border-radius: 10px;
`;

const IndiaAIFestivalComponents = (props) => {
  const { t } = useTranslation();

  return (
    <Box className="mb-3 mb-md-5 p-4">
      <h2 className="mar-b-30 text-center">{t("evaluation_of_entries")}</h2>
      <div className="row">
        <div className="col-md-8">
          <strong>{t("level_1")}:</strong>
          <StyledList>
            <li className="lead">{t("level_1_description_1")}</li>
            <li className="lead">{t("level_1_description_2")}</li>
            <li className="lead">{t("level_1_description_3")}</li>
          </StyledList>
          <strong>{t("level_2")}:</strong>
          <StyledList>
            <li className="lead">{t("level_2_description_1")}</li>
            <li className="lead">{t("level_2_description_2")}</li>
          </StyledList>
          <small className="font-italic">
            {t("evaluation_rubrics_available")}{" "}
            <a
              className="text-primary font-weight-bold"
              href="/assets/pdf/evaluation-impact-creators.pdf"
            >
              {t("here")}
            </a>{" "}
            {t("evaluation_rubrics_shapers_available")}{" "}
            <a
              className="text-primary font-weight-bold"
              href="/assets/pdf/evaluation-impact-shapers-nurturers.pdf"
            >
              {t("here")}
            </a>
          </small>
        </div>

        <div className="col-md-4 d-flex">
          <div className="w-100 px-3 mt-n4">
            <img src="/images/evaluation.png" className="w-100" />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default IndiaAIFestivalComponents;
