import React, { Component } from "react";
import Header from "../Header/header";

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (e) => {
    const { location } = this.props;
    fetch(
      `${process.env.REACT_APP_API_URL}/verify${location.pathname.substring(
        13
      )}`
    )
      .then((res) => res.json())
      .then((data) => this.setState({ data }));
  };

  render() {
    return (
      <div className="page-account-verification">
        <Header hideSubHeader={true} />

        <h2 className="page-title text-center">
          {" "}
          Please click below to verify your account
        </h2>
        <div className="text-center">
          <button
            className="btn-theme-primary btn-lg border-radius mt-3 mb-3 px-4"
            onClick={() => {
              this.onSubmit();
              this.props.history.push("/login");
            }}
          >
            Click Here to Verify
          </button>
        </div>
      </div>
    );
  }
}

export default Verification;
