import React from "react";
import { useTranslation } from "react-i18next";
import { isSubmissionOpen } from "../../utils/impactFestival";

const IndiaAIFestivalCTA = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center flex-column">
      <div className="d-flex flex-column align-items-center">
        <p className="text-center mt-4">{t("cta_description")}</p>
        <div className="d-flex flex-column flex-md-row">
          {isSubmissionOpen() ? (
            <p className="text-center header border-0 px-md-4 mx-4">
              <a
                href="/signup"
                className="header-link m-0 sign-up text-nowrap px-4 py-3"
              >
                {t("cta_button")}
              </a>
            </p>
          ) : (
            <p className="text-center header border-0 px-md-4 mx-4">
              <button disabled className="header-link m-0 sign-up danger text-nowrap px-4 py-3">
                Submissions closed
              </button>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndiaAIFestivalCTA;
