import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  border: 3px solid #ff8f4e;
  border-radius: 10px;
`;

const ImageContainer = styled.div`
  width: 45px;
  height: 45px;
  display: none;
`;

const StyledList = styled.ul`
  list-style-type: disc;
`;

const IndiaAIFestivalSpecialAwards = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-center">{t("special_awards_title")}</h2>
      <Container>
        <div className="w-100 rounded-10 py-3 px-3">
          <div className="d-flex mb-3 justify-content-center">
            <div className="d-flex align-items-center w-100">
              <h5 className="text-blue flex-grow-1 mb-0 text-center">
                {t("nari_shakti_title")}
              </h5>
              <ImageContainer>
                <img src="/images/inclusivity.png" className="w-100" />
              </ImageContainer>
            </div>
          </div>

          <StyledList className="pl-4">
            <li className="mb-2">{t("nari_shakti_list_item")}</li>
          </StyledList>
        </div>
        <div className="w-100 rounded-10 py-3 px-3">
          <div className="d-flex mb-3 justify-content-center">
            <div className="d-flex align-items-center w-100">
              <h5 className="text-blue flex-grow-1 mb-0 text-center">
                {t("accessibility_title")}
              </h5>
              <ImageContainer>
                <img src="/images/accessibility.png" className="w-100" />
              </ImageContainer>
            </div>
          </div>

          <StyledList className="pl-4">
            <li className="mb-2">{t("accessibility_list_item")}</li>
          </StyledList>
        </div>
        <div className="w-100 rounded-10 py-3 px-3">
          <div className="d-flex mb-3 justify-content-center">
            <div className="d-flex align-items-center w-100">
              <h5 className="text-blue flex-grow-1 mb-0 text-center">
                {t("diversity_title")}
              </h5>
              <ImageContainer>
                <img src="/images/technology.png" className="w-100" />
              </ImageContainer>
            </div>
          </div>

          <StyledList className="pl-4">
            <li className="mb-2">{t("diversity_list_item_1")}</li>
            <li className="mb-2">{t("diversity_list_item_2")}</li>
          </StyledList>

          <p className="mb-0 font-italic">
            {t("aspirational_districts_info")}{" "}
            <a
              href="https://www.niti.gov.in/sites/default/files/2023-07/List-of-112-Aspirational-Districts%20%281%29.pdf"
              className="mb-0 font-italic text-primary font-weight-bold"
              target="_blank"
            >
              {t("aspirational_districts_link")}
            </a>
          </p>
        </div>
      </Container>
    </>
  );
};

export default IndiaAIFestivalSpecialAwards;
