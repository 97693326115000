import React, { Component } from "react";
import Select from "react-select";
import CKEditor from "react-ckeditor-component";
import axios from "axios";
import SecondHeader from "../Header/secondheader";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

class addblog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      data: [],
      content: "",
      selectedOption: null,
      image: "",
      errors: {},
      description: "",
      maxTagsSelected: false,
    };
    this.submitted = false;
  }

  onIChange = (evt) => {
    this.setState({
      content: evt.editor.getData(),
    });
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  photoUploadHandler = (e) => {
    const bcs = e.target.files[0];
    const fd = new FormData();
    fd.append("file", bcs);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/upload`,
        fd,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
        {
          onUploadProgress: (ProgressEvent) => {
            this.setState({
              loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100,
              done: false,
            });
          },
        }
      )
      .then((res) => {
        this.setState({ image: res.data.path });
      });
  };

  createNotification = (type) => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success(
          "It will be posted after review by the team",
          "Blog submitted",
          7000
        );

        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Error message", "Click me!", 5000, () => {
          alert("callback");
        });
        break;
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidUpdate(prevProps) {
    if (this.submitted && Object.keys(this.state.errors).length != 0) {
      let errorElement = document.querySelector(".blog-error");
      window.scrollTo({
        top: errorElement.offsetTop,
        behavior: "smooth",
      });
      this.submitted = false;
    }
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/forums/tags`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          data: data.tags,
        });
      });
  }

  handleChange = (selectedOption) => {
    if (selectedOption && selectedOption.length > 3) {
      this.setState({ maxTagsSelected: true });
      return;
    }
    this.setState({ selectedOption, maxTagsSelected: false });
  };

  onSubmit = (e) => {
    this.submitted = true;
    const id = localStorage.getItem("studentId");
    let errors = {};
    e.preventDefault();

    if (!this.state.image) {
      errors["image"] = "Please upload a cover image";
    }

    if (!this.state.title) {
      errors["title"] = "Please add a title";
    }

    if (!this.state.summary) {
      errors["summary"] = "Please add summary";
    }

    if (!this.state.content) {
      errors["content"] = "Please add description";
    }

    if (!this.state.selectedOption) {
      errors["tags"] = "Please select at least one tag";
    }

    if (Object.keys(errors).length != 0) {
      this.setState({ errors: errors });
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/blogs`, {
      method: "POST",

      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        cover: this.state.image,
        title: this.state.title,
        content: this.state.content,
        tags: this.state.selectedOption,
        author: id,
        excerpt: e.target.excerpt.value,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.createNotification("success");
        setTimeout(() => {
          this.props.history.push("/blog");
        }, 2000);
      });
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <React.Fragment>
        <NotificationContainer />
        <SecondHeader />

        <div className="container pt-265p pt-md-200p">
          <div className="col-md-12 col-lg-12 pb-50 px-0">
            <div className="card login-signup-card shadow-lg mb-0 border-0">
              <div className="card-body px-md-5 py-5">
                <form
                  className="login-signup-form"
                  method="post"
                  onSubmit={this.onSubmit}
                >
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <h3 className="text-blue">Add Blog</h3>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="col-md-8 mar-b-30">
                      <label>Cover Image</label>
                      <div className="row blogs-cover">
                        <input
                          onChange={this.photoUploadHandler}
                          type="file"
                          accept="image/*"
                          className="form-control"
                        />
                        <small>Select Cover Image*</small>
                      </div>
                      {this.state.errors.image && (
                        <small className="text-danger blog-error">
                          {this.state.errors.image}
                        </small>
                      )}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="col-sm-8 mar-b-30">
                      <div className="form-group">
                        <label className="pb-1">Title*</label>
                        <div className="input-group input-group-merge">
                          <input
                            onChange={this.onChange}
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder="Title"
                            id="title"
                          />
                        </div>
                        {this.state.errors.title && (
                          <small className="text-danger blog-error">
                            {this.state.errors.title}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-8 mar-b-30">
                      <div className="form-group">
                        <label className="pb-1">Summary*</label>
                        <div className="input-group input-group-merge">
                          <textarea
                            className="form-control"
                            name="excerpt"
                            placeholder="Text here"
                            onChange={this.onChange}
                            id="summary"
                          ></textarea>
                        </div>
                        {this.state.errors.summary && (
                          <small className="text-danger blog-error">
                            {this.state.errors.summary}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="form-group col-sm-8 mar-b-30">
                      <label className="pb-1">Description*</label>
                      <div className="input-group-signup input-group-merge">
                        <CKEditor
                          activeClass="p10"
                          name="content"
                          content={this.state.content}
                          events={{
                            change: this.onIChange,
                          }}
                        />
                      </div>
                      {this.state.errors.content && (
                        <small className="text-danger blog-error">
                          {this.state.errors.content}
                        </small>
                      )}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="form-group col-sm-8 mar-b-30">
                      <label className="pb-1">Tags*</label>
                      <div className="input-group-signup input-group-merge">
                        <Select
                          className="form-select"
                          value={selectedOption}
                          onChange={this.handleChange}
                          isMulti
                          options={this.state.data}
                          isValidNewOption={(option) => console.log(option)}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        {this.state.errors.tags && (
                          <small className="text-danger blog-error">
                            {this.state.errors.tags}
                          </small>
                        )}
                        {this.state.maxTagsSelected && (
                          <small className="text-danger">
                            Maximum three tags can be added
                          </small>
                        )}
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn-blue btn-lg btn-block solid-btn border-radius mt-4 mb-3 signup-btn"
                    id="signIn"
                    disabled={this.state.disableBtn}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default addblog;
