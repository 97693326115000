import React, { Component } from "react";

import Header from "../Header/header";
import SecondHeader from "../Header/secondheader";
import Footer from "../Footer/index";
import Platform from "../../static/img/providePlatform.png";
import Knowledge from "../../static/img/leverageKnowledge.png";
import Awareness from "../../static/img/spreadAwareness.png";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {localStorage.getItem("jwtToken") == null ? (
          <Header />
        ) : (
          <SecondHeader />
        )}

        <section className="our-blog-section  gray-light-bg">
          <div className=" blog-container shadow">
            <div className="container">
              <div className="row user-info-container">
                <div className="col-md-12">
                  <h1 className="mar-b-30 text-center">
                    About - AI Student Community
                  </h1>
                  <p className="mar-b-30 text-left lead">
                    AI Student Community (AISC) is a collaborative initiative by
                    the Central Board of Secondary Education (CBSE), the apex
                    body under the Central Government’s Ministry of Education
                    and Intel India.
                  </p>
                </div>
                <div className="d-flex align-items-center flex-column flex-md-row mb-3 mb-md-5">
                  <div className="offset-md-1 col-md-3">
                    <img
                      src="img/about-us.png"
                      alt="img"
                      style={{ width: "inherit" }}
                    />
                  </div>
                  <div className="col-md-7">
                    <p className="mb-2 text-left lead">
                      AI Student Community (AISC) has been envisaged to help
                      build a digital-first mindset and support an AI-Ready
                      generation. It has been designed as a youth-driven
                      community of practice enabling collaborative learning,
                      sharing, and creating real-life social impact AI
                      solutions.
                    </p>
                  </div>
                </div>
              </div>

              <div className="mb-3 mb-md-5">
                <h2 className=" mar-b-30 text-center mt-4">
                  The AI Student Community (AISC) aims to
                </h2>
                <div>
                  <div className="row py-4">
                    <div className="px-5 col-md-4 text-center">
                      <img
                        src={Platform}
                        alt=""
                        className="hero-icon-landing"
                      />
                      <h5 className="lead">
                        Provide a platform for students to come together for
                        learning and sharing experiences
                      </h5>
                    </div>
                    <div className="px-5 col-md-4 text-center">
                      <img
                        src={Knowledge}
                        alt=""
                        className="hero-icon-landing"
                      />

                      <h5 className="lead">
                        Leverage their Knowledge to create AI-enabled social
                        impact solutions
                      </h5>
                    </div>
                    <div className="px-5 col-md-4 text-center">
                      <img
                        src={Awareness}
                        alt=""
                        className="hero-icon-landing"
                      />

                      <h5 className="lead">
                        Spread AI awareness in an inclusive way
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 mb-md-5">
                <h2 className="mar-b-30 text-center mt-4">
                  Why join the AISC?
                </h2>
                <div className="row py-4">
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-science-blue h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-blue">Connect and Collaborate</h5>
                        <p className="lead">
                          with like-minded people from across the country
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-science-blue h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-blue">Learn AI</h5>
                        <p className="lead">
                          as a skill through webinars with Intel® AI certified
                          coaches and experts
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-science-blue h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-blue">
                          Get Access to the right resources
                        </h5>
                        <p className="lead">curated from across the world</p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-science-blue h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-blue">
                          Participate in Online challenges
                        </h5>
                        <p className="lead">
                          to test your AI knowledge and up-level yourself
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-science-blue h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-blue">
                          Discuss, Deliberate & Share
                        </h5>
                        <p className="lead">
                          on relevant AI topics through Blogs & Forum section
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-3 col-md-4 text-center">
                    <div className="border border-theme-science-blue h-100 w-100 rounded-10 py-4 px-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h5 className="text-blue">
                          Build social impact solutions
                        </h5>
                        <p className="lead">
                          Using your AI knowledge and get an opportunity to
                          showcase them in national and international
                          competitions
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3 mb-md-5">
                <div className="col-md-12 text-center">
                  <h2 className="mar-b-30 text-center">
                    Who can join the AISC?
                  </h2>
                </div>
                <div className="col-md-12 text-center">
                  <p className="mar-b-30 text-left lead">
                    The community will be open for students from across the
                    country – from CBSE and non-CBSE Schools
                  </p>
                </div>
              </div>
              <div className="join-list">
                <h2 className="mar-b-30 text-center">How to join AISC?</h2>

                <li className="lead text-black">
                  <a
                    style={{ color: "#0056b3" }}
                    href="http://www.aistudent.community"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    Click here
                  </a>{" "}
                  to visit the AI Student Community Home page
                </li>
                <li className="lead text-black">
                  Click on ‘
                  <a
                    style={{ color: "#0056b3" }}
                    href="http://www.aistudent.community/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    Sign Up
                  </a>
                  ’; fill in the required details and submit
                </li>
                <li className="lead text-black">
                  A verification email will be sent on your registered email-ID
                </li>
                <li className="lead text-black">
                  Open the email, click on the link provided to verify your
                  email address
                </li>
                <li className="lead text-black">
                  This will create your profile and confirm your registration as
                  a member of the AISC
                </li>
                <li className="lead text-black">
                  You can now Log in anytime using your Email address and
                  Password
                </li>
                <p className="text-center mt-4">
                  To know more, download the information manual{" "}
                  <a
                    style={{ color: "#0056b3" }}
                    href="assets/pdf/information-manual.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default AboutUs;
