import React, { Component } from "react";
import moment from "moment";
import { FaCalendarAlt, FaRegClock } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import DOMPurify from "dompurify";

class Card extends Component {
  render() {
    const {
      id,
      title,
      desc,
      link,
      cover,
      start_time,
      end_time,
      event_creator,
      excerpt,
      student_data,
    } = this.props.data;

    const footer = this.props.footer;

    const openLink = () => {
      if (link) {
        window.open(link);
      } else if (event_creator) {
        this.props.history.push(`single_event/${id}`);
      } else if (student_data) {
        this.props.history.push(`singleblog/${id}`);
      }
    };

    const day = start_time ? (
      <div className="mr-2 pl-0 pb-2">
        <FaCalendarAlt className="mr-2" />
        {moment(start_time).format("LL")}{" "}
      </div>
    ) : null;

    const startToEnd =
      start_time && end_time ? (
        <div className="pb-2">
          <FaRegClock className="mr-2" />
          {moment(start_time).format("hh:mm A")}
        </div>
      ) : null;

    const cardDescription = desc
      ? desc.length > 100
        ? desc.slice(0, 100) + "..."
        : desc
      : excerpt.length > 100
      ? excerpt.slice(0, 100) + "..."
      : excerpt;

    return (
      <div
        className="card m-2 card-dashboard"
        style={{ borderRadius: "10px", borderWidth: "1px" }}
      >
        <div
          onClick={() => openLink()}
          className="card-img-top card-img-top-dashboard"
          style={{
            height: "160px",
            background: `url(${cover})`,
            backgroundSize: "cover",
          }}
        />
        <div
          className="card-body"
          style={{ color: "#435370", padding: "14px" }}
        >
          <h5
            onClick={() => openLink()}
            className="card-title cursor-pointer"
            style={{ minHeight: "unset", fontSize: "18px", fontWeight: "800" }}
          >
            {title}
          </h5>
          <div className="d-flex">
            {day}
            {startToEnd}
          </div>
          <p
            className="card-text"
            style={{
              lineHeight: "1.3",
              color: "#3c4c6b",
              fontWeight: 500,
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(cardDescription),
            }}
          ></p>
        </div>
        {event_creator && footer(event_creator, id)}
        {student_data &&
          footer(student_data[0].first_name, id, student_data[0].profile_pic)}
        {link && footer(link)}
        {event_creator || student_data || link ? null : footer}
      </div>
    );
  }
}

export default withRouter(Card);
