import React from "react";
import Header from "../Header/header";
import { connect } from "react-redux";
import { ssoLoginUser, ssoLoginUserViaId } from "../../actions/authAction";
import PropTypes from "prop-types";
import "../../index.css";
import Footer from "../Footer/index";
import * as QueryString from "query-string";

class SsoLogin extends React.Component {
  constructor(props) {
    super(props);
    const queryValues = QueryString.parse(props.location.search);
    this.state = {
      hero: {},
      email: "",
      password: "",
      disableBtn: false,
      btnText: "Sign In",
      sso: queryValues.sso || "",
      ssoSig: queryValues.sig || "",
      isLoadingSso: localStorage.getItem("studentId") !== null,
    };
    if (localStorage.getItem("studentId") !== null) {
      const userData = {
        studentId: localStorage.getItem("studentId"),
        ssoPayload: this.state.sso,
        ssoSig: this.state.ssoSig,
      };
      this.props.ssoLoginUserViaId(userData);
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
      ssoPayload: this.state.sso,
      ssoSig: this.state.ssoSig,
    };
    this.props.ssoLoginUser(userData);
  };

  render() {
    if (this.state.isLoadingSso) {
      return "Taking you to the forum...";
    }
    return (
      <React.Fragment>
        <Header />
        <div className="pt-265p pt-md-200p">
          <section className="page-login pt-30 pb-5">
            <div className="container">
              <div className="row align-items-center justify-content-between pt-sm-5 pt-md-5 pt-lg-0 login-container">
                <div className="col-md-5 col-lg-5 login-inner-container">
                  <div className="card login-signup-card shadow-lg mb-0">
                    <div className="card-body px-md-5 py-5">
                      <h1 className="mb-1 page-title">Login</h1>
                      <p className="mb-5 text-muted">
                        Sign in to your account to continue.
                      </p>

                      <form
                        className="login-signup-form"
                        method="post"
                        onSubmit={this.onSubmit}
                      >
                        <div className="form-group">
                          <label className="pb-1">Email Address</label>
                          <div className="input-group input-group-merge">
                            <input
                              onChange={this.onChange}
                              value={this.state.email}
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="name@yourdomain.com"
                              required
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col">
                              <label className="pb-1">Password</label>
                            </div>
                          </div>
                          <div className="input-group input-group-merge">
                            <input
                              onChange={this.onChange}
                              value={this.state.password}
                              id="password"
                              type="password"
                              className="form-control"
                              placeholder="Enter your password"
                              required
                            />
                          </div>
                          {/* Error message show */}
                          {this.props.error.error !== null ? (
                            <p style={{ color: "red" }}>
                              Invalid Usename or Password
                            </p>
                          ) : null}
                        </div>
                        <button
                          type="submit"
                          className="btn-theme-primary btn-lg border-radius mt-4 mb-3"
                          id="signIn"
                        >
                          {this.state.btnText}
                        </button>
                      </form>
                    </div>
                    <div className="card-footer bg-transparent border-top px-md-5">
                      <div className="row mx-0">
                        <div className="col-md-8 py-2 pl-md-0">
                          <small>Not registered?</small>
                          <a href="signup" className="small text-blue">
                            {" "}
                            Create account
                          </a>
                        </div>
                        <div className="col-md-4 text-md-right py-2 pr-md-0">
                          <a
                            href="/resetPassword"
                            className="small text-blue text-right"
                          >
                            {" "}
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
SsoLogin.propTypes = {
  ssoLoginUser: PropTypes.func.isRequired,
  ssoLoginUserViaId: PropTypes.func.isRequired,
  // auth: PropTypes.object.isRequired,
  // errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    error: state.authFail,
  };
};

export default connect(mapStateToProps, { ssoLoginUser, ssoLoginUserViaId })(
  SsoLogin
);
