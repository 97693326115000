/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authAction";
import Footer from "../Footer/index";
import SecondHeader from "../Header/secondheader";
import rightArrow from "../../static/img/right-arrow.png"; //right-arrow.png
import { Link } from "react-router-dom";
import returnIcon from "../../static/img/return.png";
import YouTube from "react-youtube";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const id = localStorage.getItem("studentId");

    fetch(
      `${
        process.env.REACT_APP_API_URL
      }/student/${id}/topic${location.pathname.replace(/\/single_course/, "")}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result.data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  videoCompleted(id) {
    const studentId = localStorage.getItem("studentId");
    fetch(
      `${process.env.REACT_APP_API_URL}/topicCompleted?studentId=${studentId}&topicId=${id}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then(
        (result) => {},
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <SecondHeader />
        <section className="our-blog-section gray-light-bg single-blog-container">
          {data.map((data, key) => {
            if (data.first_time_topic) {
              return (
                <div
                  className="blog-container container mx-auto shadow rounded"
                  key={key}
                >
                  <div className="row mx-0">
                    <div className="col-12 px-0">
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <p className="mb-0">
                          Welcome to a new video, DO NOT forget to check out the
                          experiences and links!
                        </p>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          <div className="blog-container container mx-auto shadow rounded">
            <Link to="/letslearn">
              <div
                className="text-blue go-back-mar"
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                <img className="return-icon" src={returnIcon} alt="icon" />{" "}
                <p className="return-label"> Go Back</p>
              </div>
            </Link>
            {data.map((data, key) => {
              let videoId = data.video.split("/");
              videoId = videoId[videoId.length - 1];
              return (
                <div className="row" key={key}>
                  <div className="col-md-12 mar-b-30 mt-2">
                    <h3 className="text-left ml-3">
                      {data.title}
                      {data.upcoming_id && (
                        <a href={"/single_course/" + data.upcoming_id}>
                          <img
                            className="next-arrow"
                            src={rightArrow}
                            alt="leftarrow"
                          />
                        </a>
                      )}
                    </h3>
                    <div className="row">
                      <div className="col-md-12 video-container mt-1 mx-4">
                        <YouTube
                          videoId={[videoId]}
                          opts={{}}
                          onEnd={() => {
                            this.videoCompleted(data.order);
                          }}
                        ></YouTube>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <p className="course-page-desc ml-4">{data.desc}</p>
                    </div>
                    <div className="col-md-4 no-padding">
                      <div className="inner-link-container">
                        <div key={key}>
                          <h4 className="pad-b-15 white border-bottom-white ">
                            <span className="mar-l-15 mar-b-15">Links</span>
                          </h4>
                          {data.links.map((links, key) => (
                            <a href={links.link} target="_blank" key={key}>
                              <div className="single-link">
                                <p className="white link-title">
                                  {links.title}
                                </p>
                                <p className="white link-desc">{links.desc}</p>
                              </div>
                            </a>
                          ))}
                        </div>

                        <div>
                          <h4 className="pad-b-15 pad-t-15 white border-bottom-white ">
                            <span className="mar-l-15 mar-b-15">
                              Experiences
                            </span>
                          </h4>
                          <div className="">
                            {data.Experiences.map((Experiences, key) => (
                              <a
                                href={Experiences.link}
                                target="_blank"
                                key={key}
                              >
                                <div className="single-link">
                                  <p className="white link-title">
                                    {Experiences.title}
                                  </p>
                                  <p className="white link-desc">
                                    {Experiences.desc}
                                  </p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
