import React from "react";
import { useTranslation } from "react-i18next";

const IndiaAIFestivalTheme = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-3 mb-md-5">
      <h2 className="text-center mt-4">{t("timeline_heading_text")}</h2>
      <div className="mt-3 mt-md-5">
        <img src="/images/timeline.png" className="w-100" />
      </div>
    </div>
  );
};

export default IndiaAIFestivalTheme;
