import React from "react";

const IndiaAIFestivalTheme = (props) => {
  return (
    <div className="mb-3 mb-md-5">
      <h2 className=" mar-b-30 text-center mt-4">Theme</h2>
      <div>
        <p className="text-justify">
          The theme for the India AI Impact Contest is{" "}
          <strong>‘AI with a purpose’</strong>. Participants are encouraged to
          develop AI/ AIoT-enabled solutions that address social, environmental
          and economic challenges faced by communities in India and/or globally.
          Solutions should be aligned to the UN SDGs and demonstrate the clear
          impact on improving lives and fostering an inclusive growth for all,
          especially the underserved.
        </p>
      </div>
    </div>
  );
};

export default IndiaAIFestivalTheme;
