import React, { Component } from "react";
// import HeroSection from "../components/HeroSection/HeroSectionBlog";
import BlogSection from "../components/Blog";
import FooterSection from "../components/Footer";
import SecondHeader from "../components/Header/secondheader";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <SecondHeader />
        <BlogSection />
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Theme;
