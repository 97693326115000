import React, { Component } from "react";
import Slider from "react-slick";
import { ReactComponent as NextArrowIcon } from "../static/img/arrow.svg";
import { ReactComponent as PreviousArrowIcon } from "../static/img/back.svg";

class SliderReact extends Component {
  render() {
    const hoverColor = this.props.hoverColor ? "white-arrows" : "arrows";
    const buttonMoveAway = this.props.buttonMoveAway;
    const slidesToShow = this.props.slidesToShow ? this.props.slidesToShow : 3;
    const variableWidth = this.props.variableWidth === false ? false : true;
    function NextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <NextArrowIcon
          className={`${className} ${hoverColor} `}
          style={
            buttonMoveAway
              ? {
                  ...style,
                  right: "140px",
                  top: "-30px",
                  height: "auto",
                  width: "35px",
                  fill: "#768297",
                }
              : {
                  ...style,
                  right: "0px",
                  top: "-30px",
                  height: "auto",
                  width: "35px",
                  fill: "#768297",
                }
          }
          onClick={onClick}
        />
      );
    }
    function PrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <PreviousArrowIcon
          className={`${className} ${hoverColor}`}
          style={
            buttonMoveAway
              ? {
                  ...style,
                  display: "block",
                  right: "170px",
                  left: "unset",
                  height: "auto",
                  width: "35px",
                  fill: "#768297",
                  top: "-30px",
                }
              : {
                  ...style,
                  display: "block",
                  right: "40px",
                  left: "unset",
                  height: "auto",
                  width: "35px",
                  fill: "#768297",
                  top: "-30px",
                }
          }
          onClick={onClick}
        />
      );
    }

    const settings = {
      infinite: false,
      slidesToShow: slidesToShow,
      variableWidth: variableWidth,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
    return (
      <>
        <Slider {...settings}>{this.props.children}</Slider>
      </>
    );
  }
}

export default SliderReact;
