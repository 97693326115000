import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

// importing all the themes
import Theme from "../themes/theme";
import Login from "../components/Auth/login";
import SSOLogin from "../components/Auth/ssoLogin";
import SSOLogout from "../components/Auth/ssoLogout";
import SignUp from "../components/Auth/signup";
import ResetPassword from "../components/Auth/resetPassword";
import ChangePassword from "../components/Auth/changePassword";
import Download from "../themes/download";
import Faq from "../themes/faq";
import CommunityGuidelines from "../themes/communityGuidelines";
import Disclaimer from "../themes/disclaimer";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import ThankYou from "../themes/thankyou";
import Blog from "../themes/blog";
import Singleblog from "../components/Blog/singleblog";
import Event from "../components/Event/event";
import Challenge from "../components/Challenge/challenge";
import SingleChallenge from "../components/Challenge/single_challenge";
import SingleEvent from "../components/Event/single_event";
import Dashboard from "../Dashboard/Dashboard";
import Lerslearn from "../components/Dashboard/letslearn";
import SingleCourse from "../components/Dashboard/single_course";
import About from "../components/AboutUs/AboutUs";
import Profile from "../components/Auth/profile";
import Unverification from "../components/Verification/Unverification";
import Verification from "../components/Verification/Verification";
import { createBrowserHistory } from "history";
import addblog from "../components/Blog/addblog";
import Editblog from "../components/Blog/edit-blogs";
import LearningResources from "../components/LearningResources/LearningResources";
import ExemplarBlogs from "../components/ExemplarBlogs/ExemplarBlogs";
import SingleExemplarBlog from "../components/ExemplarBlogs/SingleExemplarBlog";
import AIProjects from "../components/AIProjects/AIProjects";
import HomeOld from "../components/HomepageSections/HomeOld";
import ReactGA from "react-ga";
import { Page404 } from "../components/page404/page404";
import Attendance from "../components/Attendance/Attendance";
import GenerateCertificate from '../pages/generateCertificate';

import {
  IndiaAIFestivalPage,
  IndiaAIFestivalRegistrationPage,
  IndiaAIFestivalFAQPage,
  IndiaAIFestivalGuidelinesPage,
} from "../pages";

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("jwtToken") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )
    }
  />
);

function useImpactFestivalSource() {
  let location = useLocation();

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const iaiifSource = queryParams.get("iaiif_source");

    if (iaiifSource) {
      localStorage.setItem("iaiif_source", iaiifSource);
    }
  }, [location]);
}

const SwitchRoutes = () => {
  useImpactFestivalSource();

  return (
    <Switch>
      <Route exact path="/" component={HomeOld} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/sso" component={SSOLogin} />
      <Route exact path="/ssologout" component={SSOLogout} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/AIProjects" component={AIProjects} />
      <Route exact path="/learningResources" component={LearningResources} />
      <Route exact path="/exemplar-blogs" component={ExemplarBlogs} />
      <Route exact path="/exemplar-blog/:id" component={SingleExemplarBlog} />

      <Route exact path="/resetPassword" component={ResetPassword} />
      <Route exact path="/changePassword/:token" component={ChangePassword} />
      <Route exact path="/download" component={Download} />
      <Route exact path="/faq" component={Faq} />
      <Route
        exact
        path="/community-guidelines"
        component={CommunityGuidelines}
      />
      <Route exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/home-old" component={HomeOld} />
      <Route exact path="/404" component={NotFound} />
      <Route exact path="/comingSoon" component={ComingSoon} />
      <Route exact path="/thankyou" component={ThankYou} />
      <Route exact path="/about" component={About} />
      <Route
        exact
        path="/india-ai-impact-festival-2024"
        component={IndiaAIFestivalPage}
      />
      <Route
        exact
        path="/india-ai-impact-festival-faqs"
        component={IndiaAIFestivalFAQPage}
      />
      <Route
        exact
        path="/india-ai-impact-festival-guidelines"
        component={IndiaAIFestivalGuidelinesPage}
      />
      <Route exact path="/unverification" component={Unverification} />

      <Route exact path="/verification/:token" component={Verification} />
      <Route
        exact
        path="/generate-certificate"
        component={GenerateCertificate}
      />
      {/* using private Route */}
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/blog" component={Blog} />
      <PrivateRoute exact path="/event" component={Event} />
      <PrivateRoute
        exact
        path="/india-ai-impact-festival-registration-2024"
        component={IndiaAIFestivalRegistrationPage}
      />
      <PrivateRoute exact path="/singleblog/:id" component={Singleblog} />
      <PrivateRoute exact path="/single_event/:id" component={SingleEvent} />
      <PrivateRoute
        exact
        path="/single_challenge/:id"
        component={SingleChallenge}
      />
      <PrivateRoute exact path="/editblog/:id" component={Editblog} />
      <PrivateRoute exact path="/event" component={Event} />
      <PrivateRoute exact path="/challenge" component={Challenge} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/letslearn" component={Lerslearn} />
      <PrivateRoute exact path="/single_course/:id" component={SingleCourse} />
      <PrivateRoute exact path="/add-newblog" component={addblog} />
      <PrivateRoute exact path="/mark-attendance/:id" component={Attendance} />
      <Route component={Page404} />
    </Switch>
  );
};

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router history={history}>
          <SwitchRoutes />
        </Router>
      </React.Fragment>
    );
  }
}
