import React, { Component } from "react";
import ChangingFields from "./ChangingFields/ChangingFields";

class CommunitySection extends Component {
  render() {
    const initialState = {
      title: "Connect and Collaborate",
      description: (
        <ul className="list-group join-list text-white">
          <li className="mb-md-3"> Meet like minded people.</li>
          <li className="mb-md-3"> Work with fellow AI enthusiasts.</li>
          <li className="mb-md-3"> Share your interest and knowledge.</li>
          <li className="mb-md-0"> Show your nerdiness!</li>
        </ul>
      ),
      icon: "fa-connectdevelop",
      imageUrl: "images/connect.jpg",
    };
    const joiningReasons = [
      initialState,

      {
        title: "Get Access to the right toolset",
        description: (
          <ul className="list-group join-list text-white">
            <li className="mb-md-3">
              Attend live online training sessions with Intel certified AI
              coaches and other Industry experts
            </li>
            <li className="mb-md-3">
              Join the self-learning environment and explore the AI World on
              your own
            </li>
            <li className="mb-md-0">
              Participate in Challenges, Competitions and win digital badges!
            </li>
          </ul>
        ),
        icon: "fa-server",
        imageUrl: "images/access.jpg",
      },
      {
        title: "Build AI enabled solutions",
        description: (
          <ul className="list-group join-list text-white">
            <li className="mb-md-4">
              Give wings to your ideas and create AI led social impact solutions
              with support from AI coaches and mentors
            </li>
            <li className="mb-md-0"> Support the SDGs in your own way!</li>
          </ul>
        ),

        icon: "fa-database",
        imageUrl: "images/build.jpg",
      },
    ];
    return (
      <section className="vertical-scrolling animated-row ">
        <div className="section-inner">
          <ChangingFields
            title="WHY JOIN AI STUDENT COMMUNITY?"
            reasons={joiningReasons}
            setDetails={this.setDetails}
            initialState={initialState}
          />
        </div>
      </section>
    );
  }
}

export default CommunitySection;
