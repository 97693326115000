import React, { Component } from "react";

class MemberSection extends Component {
  render() {
    const galleryItem = (heading, description, imageSource, icon) => {
      return (
        <div className="owl-item" style={{ width: "324px" }} key={heading}>
          <div className="item" style={{ width: "100%" }}>
            <div className="portfolio-item">
              {icon ? (
                icon
              ) : (
                <>
                  <div className="thumb">
                    <img src={imageSource} alt="" />
                  </div>

                  <div className="thumb-inner">
                    <h4 style={{ fontSize: "24px" }}>{heading}</h4>
                    <p style={{ fontSize: "18px" }}>{description}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      );
    };

    const allItems = [
      {
        heading: "Forum Threads",
        description:
          "Learn more by interacting with AI enthusiasts just like yourself. Being inquisitive is the first step to learning!",
        imageSource: "images/forum.jpg",
      },
      {
        heading: "Events",
        description: "Be a part of exclusive Community events!",
        imageSource: "images/events.jpg",
      },
      {
        heading: "Exclusive sessions",
        description: (
          <>
            Intel(R) AI For Youth Mentor <br />
            Dictionaries: Cool things in Python 2
          </>
        ),
        imageSource: "images/exclusive-sessions.jpg",
      },
      {
        heading: "Blogs",
        description:
          "Submit Blogs about what you’ve built! Also go through insightful blogs by other students, teachers and experts.",
        imageSource: "images/blog.jpg",
      },
      {
        heading: "Challenges",
        description: "Participate in Challeneges and Skill-Up!",
        imageSource: "images/challenges.jpg",
      },
      {
        heading: "Know more",
        description: "",
        icon: (
          <a href="/signup" className="know-more-button">
            <span className="mr-2">Know more </span>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        ),
      },
    ];
    return (
      <section className="vertical-scrolling animated-row ">
        <div className="section-inner">
          <div className="row justify-content-center mt-5">
            <div className="col-md-8 wide-col-laptop mt-5">
              <div className="title-block animate" data-animate="fadeInUp">
                <h1>Members only area</h1>
              </div>
              <div className="gallery-section animate" data-animate="fadeInUp">
                <div className="gallery-list owl-carousel owl-loaded owl-drag">
                  <div className="owl-stage-outer">
                    <div className="owl-stage ">
                      {allItems.map((item) =>
                        galleryItem(
                          item.heading,
                          item.description,
                          item.imageSource,
                          item.icon
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default MemberSection;
