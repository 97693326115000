import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  border: 3px solid #ff8f4e;
  border-radius: 10px;
`;

const ImageContainer = styled.div`
  width: 45px;
  height: 45px;
  display: none;
`;

const StyledList = styled.ul`
  list-style-type: disc;
`;

const IndiaAIFestivalMainAwards = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="text-center">{t("national_awards_title")}</h2>
      <Container>
        <div className="w-100 rounded-10 py-3 px-3">
          <div className="d-flex mb-3">
            <div className="d-flex align-items-center w-100">
              <h5 className="text-blue flex-grow-1 mb-0 text-center">
                {t("impact_creators_title")}
              </h5>
              <ImageContainer>
                <img src="/images/impact-creators.png" className="w-100" />
              </ImageContainer>
            </div>
          </div>

          <StyledList className="pl-4">
            <li className="mb-2">{t("impact_creators_list_item_1")}</li>
            <li className="mb-2">{t("impact_creators_list_item_2")}</li>
            <li className="mb-2">{t("impact_creators_list_item_3")}</li>
          </StyledList>
        </div>
        <div className="w-100 rounded-10 py-3 px-3">
          <div className="d-flex mb-3">
            <div className="d-flex align-items-center w-100">
              <h5 className="text-blue flex-grow-1 mb-0 text-center">
                {t("impact_shapers_title")}
              </h5>

              <ImageContainer>
                <img src="/images/impact-shapers.png" className="w-100" />
              </ImageContainer>
            </div>
          </div>
          <StyledList className="pl-4">
            <li className="mb-2">{t("impact_shapers_list_item_1")}</li>
          </StyledList>
        </div>
        <div className="w-100 rounded-10 py-3 px-3">
          <div className="d-flex mb-3">
            <div className="d-flex align-items-center w-100">
              <h5 className="text-blue flex-grow-1 mb-0 text-center">
                {t("impact_nurturers_title")}
              </h5>

              <ImageContainer>
                <img src="/images/impact-nurturers.png" className="w-100" />
              </ImageContainer>
            </div>
          </div>
          <StyledList className="pl-4">
            <li className="mb-2">{t("impact_nurturers_list_item_1")}</li>
          </StyledList>
        </div>
      </Container>
    </div>
  );
};

export default IndiaAIFestivalMainAwards;
