import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledList = styled.ul`
  list-style-type: unset;
`;

const StyledContentContainer = styled.div`
  position: relative;
  color: white;
  z-index: 2;
  transition: color 0.3s ease;
`;

const StyledTitle = styled.h3`
  position: relative;

  &:hover {
    color: #54bfcc !important;
  }

  &:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`;

const VertItem = styled.div`
  position: relative;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  width: ${(props) => (props.active ? "50%" : "33.33333%")};
  transition: height 0.3s ease, width 0.3s ease;
  overflow: hidden;

  aspect-ratio: 1 / 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    opacity: ${(props) => (props.active ? "1" : "0")};
    transition: opacity 0.3s ease;
  }

  @media (min-width: 992px) {
    aspect-ratio: unset;
    width: ${(props) => (props.active ? "50%" : "33.33333%")};
    height: 500px;
  }
  @media (max-width: 992px) {
    width: 100%;
    height: ${(props) => (props.active ? "100%" : "50%")};
  }
`;

const IndiaAIFestivalTarget = (props) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const isActive = (index) => index === activeIndex;

  const backgrounds = [
    "images/Image_20240401_102143.png",
    "images/Image-41235312_423.png",
    "images/Image_20240401_110202_355.jpeg",
  ];

  return (
    <div className="d-md-block">
      <div className="d-flex flex-column flex-lg-row ">
        {[0, 1, 2].map((index) => (
          <VertItem
            key={index}
            background={backgrounds[index]}
            active={isActive(index)}
            onClick={() => handleClick(index)}
            className="p-4 c-pointer"
          >
            {index === 0 && (
              <StyledContentContainer
                className={`h-100 w-100 d-flex align-items-center justify-content-center ${
                  activeIndex === 0 ? "flex-column" : "flex-row"
                }`}
              >
                <StyledTitle className="text-white">
                  {t("section_1_title")}
                </StyledTitle>
                {isActive(index) && (
                  <div className="text-white" style={{ fontSize: "16px" }}>
                    {t("section_1_content")}
                  </div>
                )}
              </StyledContentContainer>
            )}
            {index === 1 && (
              <StyledContentContainer
                className={`h-100 w-100 d-flex align-items-center justify-content-center ${
                  activeIndex === 1 ? "flex-column" : "flex-row"
                }`}
              >
                <StyledTitle className="text-white">
                  {t("section_2_title")}
                </StyledTitle>
                {isActive(index) && (
                  <div className="text-box">
                    <StyledList>
                      <li>
                        <strong className="text-white">
                          {t("section_2_subtitle_1")}{" "}
                        </strong>
                        {t("section_2_content_1")}
                      </li>
                    </StyledList>
                    <StyledList>
                      <li>
                        <strong className="text-white">
                          {t("section_2_subtitle_2")}{" "}
                        </strong>
                        {t("section_2_content_2")}
                      </li>
                    </StyledList>
                    <StyledList>
                      <li>
                        <strong className="text-white">
                          {t("section_2_subtitle_3")}{" "}
                        </strong>
                        {t("section_2_content_3")}
                      </li>
                    </StyledList>
                  </div>
                )}
              </StyledContentContainer>
            )}
            {index === 2 && (
              <StyledContentContainer
                className={`text-white h-100 w-100 d-flex align-items-center justify-content-center ${
                  activeIndex === 2 ? "flex-column" : "flex-row"
                }`}
              >
                <StyledTitle className="text-white">
                  {t("section_3_title")}
                </StyledTitle>
                {isActive(index) && (
                  <StyledList>
                    <li>{t("section_3_content_1")}</li>
                    <li>{t("section_3_content_2")}</li>
                    <li>{t("section_3_content_3")}</li>
                    <li>{t("section_3_content_4")}</li>
                    <li>{t("section_3_content_5")}</li>
                    <li>{t("section_3_content_6")} </li>
                    <li>{t("section_3_content_7")}</li>
                    <li>{t("section_3_content_8")}</li>
                  </StyledList>
                )}
              </StyledContentContainer>
            )}
          </VertItem>
        ))}
      </div>
    </div>
  );
};

export default IndiaAIFestivalTarget;
