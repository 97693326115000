import React, { Component } from "react";

import { withRouter } from "react-router-dom";

class ChangingFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onHoverText: this.props.initialState.description,
      onHoverImage: this.props.initialState.imageUrl,
      onHoverTitle: this.props.initialState.title,
      redirectionLink: null,
    };
  }
  render() {
    const sectionReason = (
      title,
      description,
      icon,
      imageUrl,
      redirectionLink
    ) => (
      <div
        key={title}
        className="owl-item px-2 contact-links"
        onMouseEnter={() =>
          this.setState({
            onHoverText: description,
            onHoverImage: imageUrl,
            onHoverTitle: title,
          })
        }
      >
        <a
          href={redirectionLink}
          className="item animate"
          data-animate="fadeInUp"
        >
          <div className="counter-box ">
            <i className={`fa ${icon} counter-icon`} aria-hidden="true"></i>
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                padding: "0px 6px",
              }}
            >
              {title}
            </div>
          </div>
        </a>
      </div>
    );

    const reasons = this.props.reasons;

    const factsSection = (
      <div className="col-md-6">
        <div className="about-contentbox">
          <div className="animate" data-animate="fadeInUp">
            <h1 style={{ marginLeft: "auto" }}>{this.props.title}</h1>
          </div>
          <div className="facts-list owl-carousel owl-loaded owl-drag">
            <div className="owl-stage-outer">
              <div className="owl-stage">
                {reasons.map((reason) =>
                  sectionReason(
                    reason.title,
                    reason.description,
                    reason.icon,
                    reason.imageUrl,
                    reason.redirectionLink
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const largeSection = (
      <div className="col-md-6">
        <div
          className="join-reasons about-img animate d-flex align-items-center"
          data-animate="fadeInUp"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6 ), rgba(0, 0, 0, 0.6)),url("${this.state.onHoverImage}")`,
            backgroundSize: "cover",
          }}
        >
          <div
            className="item animate "
            style={{ height: "100%", width: "100%" }}
            data-animate="fadeInUp"
          >
            <div className="counter-box counter-box-large  p-5 h4 ">
              {this.state.onHoverText}
            </div>
            <div
              className="reason-title"
              style={this.props.inverted ? { lineHeight: "1" } : null}
            >
              {this.state.onHoverTitle}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="about-section">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-8 wide-col-laptop">
            <div className="row">
              {this.props.inverted ? (
                <>
                  {largeSection}
                  {factsSection}
                </>
              ) : (
                <>
                  {factsSection}
                  {largeSection}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ChangingFields);
