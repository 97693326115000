import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { FaCalendarAlt, FaRegClock } from "react-icons/fa";
import DOMPurify from "dompurify";

class EventCard extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.data;
  }

  enrollEvent() {
    const sid = localStorage.getItem("studentId");
    const id = this.state.id;

    fetch(`${process.env.REACT_APP_API_URL}/participate/${sid}/event/${id}`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ isRegistered: true });
      });
  }

  unEnrollEvent() {
    const sid = localStorage.getItem("studentId");
    const id = this.state.id;

    fetch(`${process.env.REACT_APP_API_URL}/unparticipate/${sid}/event/${id}`, {
      headers: {
        "x-access-token": localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ isRegistered: false });
      });
  }

  getFooter() {
    const currentTime = new Date();
    const { event_creator, id, isRegistered, end_time } = this.state;

    return (
      <div className="d-flex" style={{ width: "100%" }}>
        <div style={{ fontSize: "12px", width: "100%" }} className="d-flex p-2">
          <div className="circle mr-2"></div>
          <span>{event_creator}</span>
        </div>
        <div className="d-flex justify-content-end" style={{ width: "100%" }}>
          <Link
            to={`single_event/${id}`}
            className="btn btn-outline-primary know-more"
          >
            Know More
          </Link>
          {moment(end_time).unix() > moment(currentTime).unix() && (
            <button
              className="btn btn-primary enroll-button"
              onClick={() => {
                isRegistered ? this.unEnrollEvent() : this.enrollEvent();
              }}
            >
              {isRegistered ? "Enrolled" : "Enroll"}
            </button>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      id,
      title,
      desc,
      link,
      isRegistered,
      cover,
      start_time,
      end_time,
      event_creator,
      excerpt,
      student_data,
    } = this.state;

    const openLink = () => {
      if (link) {
        window.open(link);
      } else if (event_creator) {
        this.props.history.push(`single_event/${id}`);
      } else if (student_data) {
        this.props.history.push(`singleblog/${id}`);
      }
    };

    const day = start_time ? (
      <div className="mr-2 pl-0 pb-2">
        <FaCalendarAlt className="mr-2" />
        {moment(start_time).format("LL")}{" "}
      </div>
    ) : null;

    const startToEnd =
      start_time && end_time ? (
        <div className="pb-2">
          <FaRegClock className="mr-2" />
          {moment(start_time).format("hh:mm A")}
        </div>
      ) : null;

    const cardDescription = desc
      ? desc.length > 100
        ? desc.slice(0, 100) + "..."
        : desc
      : excerpt.length > 100
      ? excerpt.slice(0, 100) + "..."
      : excerpt;

    return (
      <div
        className="card m-2 card-dashboard"
        style={{ borderRadius: "10px", borderWidth: "1px" }}
      >
        <div
          onClick={() => openLink()}
          className="card-img-top card-img-top-dashboard"
          style={{
            height: "160px",
            background: `url(${cover})`,
            backgroundSize: "cover",
          }}
        />
        <div
          className="card-body"
          style={{ color: "#435370", padding: "14px" }}
        >
          <h5
            onClick={() => openLink()}
            className="card-title cursor-pointer"
            style={{ minHeight: "unset", fontSize: "18px", fontWeight: "800" }}
          >
            {title}
          </h5>
          <div className="d-flex">
            {day}
            {startToEnd}
          </div>
          <p
            className="card-text"
            style={{
              lineHeight: "1.3",
              color: "#3c4c6b",
              fontWeight: 500,
            }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(cardDescription),
            }}
          ></p>
        </div>
        {this.getFooter()}
      </div>
    );
  }
}

export default withRouter(EventCard);
